@import "../node_modules/@fontsource/roboto/400.css";
@import "../node_modules/@fontsource/roboto/500.css";
@import "../node_modules/@fontsource/roboto-mono/index.css";
@import "../node_modules/@fontsource/material-icons/index.css";
@import "./styles.var";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/sweetalert2/src/sweetalert2.scss";
@import "../node_modules/ngx-lightbox/lightbox.css";

html {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.toast-container {
    position: fixed !important;
}

.btn-link {
    text-decoration: none !important;
}

a {
    text-decoration: none;
    &.disabled {
        opacity: $btn-disabled-opacity;
        pointer-events: none;
    }
}
.btn {
    .material-icons {
        font-size: 1.35rem;
        @at-root {
            .btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    appearance: none;
    display: none;
}
.btn-close-search {
    background-color: $input-bg !important;
    margin-top: 2px;
    margin-right: 2px;
}
input[required]:not([type="radio"]),
ng-select[required],
select[required],
textarea[required] {
    + label:after {
        content: " *" !important;
        color: red;
        vertical-align: top;
        position: relative !important;
        inset: unset !important;
        background: transparent !important;
    }
}
.required:after {
    content: " *" !important;
    color: red;
    vertical-align: top;
    position: relative !important;
    inset: unset !important;
    background: transparent !important;
}

.ng-select {
    height: auto !important;
    .ng-select-container {
        background: none !important;
        color: unset !important;
        border-radius: unset !important;
        border: none !important;
        min-height: 22px !important;
        height: auto !important;
        border-color: unset !important;
        box-shadow: none !important;
        .ng-value-container .ng-input {
            top: unset !important;
            padding-left: 0px !important;
        }
    }
    .ng-select-container .ng-value-container {
        padding-left: 0px !important;
        flex-wrap: wrap !important;
    }
    .ng-dropdown-panel {
        z-index: 1020 !important;
    }
    &.ng-select-disabled,
    &.ng-select-readonly {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-border-color;
    }
    &.ng-select-focused {
        box-shadow: $form-select-focus-box-shadow;
        &.is-invalid {
            box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($danger, $input-btn-focus-color-opacity);
        }
    }
}
.btn-group-toggle {
    [type="radio"],
    [type="checkbox"] {
        display: none;
    }
}
.left-99 {
    left: -399px !important;
}

.left-0 {
    left: 0 !important;
}
.table {
    tr,
    td,
    th {
        vertical-align: middle;
    }
}
th[sortable] {
    cursor: pointer;
    user-select: none;
}
th[sortable].asc:after,
th[sortable].desc:after {
    content: "";
    display: inline-block;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAYAAAD6S912AAABAklEQVQ4je2VP4oCMRSHP0fB0j+N9usBtBc9gB5CZDtvIFvYWdppoSJY2AseQbBTjyBab7HNigoqgZclDJlxZrYS5gc/CO+9fAkvISGAOsAF+AU+g0zwUx94uNyLChtaYNqDsLCpD0x7FBS2CADTnvuBHGAVAqa9BBJuWA7YRIBpr4GMhuWBvaXoBIyBmxFT44nk3PVbIKuAXx6rloGSJf4BVDzmdB25tKbuQEN2XbD0ugjsgKZATJ3VOCWnpZIHoGYUVC27qBr5OnCU+AxImnTV1LRrxVdAZM7fgaSMxI/HdXqli9k2JyLEUzEwBr4D8FueLK2rxP6ltrwi6htt+ZKAJ40gfuMAQqTfAAAAAElFTkSuQmCC)
        top center no-repeat;
    background-size: 12px;
    width: 12px;
    height: 12px;
    transition: $transition-base;
}
th[sortable].desc:after {
    transform: rotate(180deg);
}
.table {
    th {
        border-bottom: none !important;
        background-color: tint-color($black, 97%);
    }
    tbody {
        border-top: none !important;
    }
}
.modal-transparent {
    .modal-content {
        background: transparent !important;
        border: 0 !important;
    }
}

.ng-select {
    .ng-select-container {
        position: relative;
        .ng-clear-wrapper {
            margin-right: 15px;
        }
        .ng-arrow-wrapper {
            position: absolute;
            z-index: 111;
            background-color: $input-bg;
            right: -10px;
        }
    }
}
.ng-select.ng-select-disabled {
    .ng-arrow-wrapper,
    .ng-clear-wrapper {
        display: none;
    }
}
.cursor-pointer {
    cursor: pointer;
}

.webcam-wrapper {
    width: 100% !important;
    height: 100% !important;
    video {
        width: 100% !important;
        height: 100% !important;
        margin-top: -2px !important;
        border-top-right-radius: $border-radius !important;
        border-top-left-radius: $border-radius !important;
    }
}

.qr {
    width: 100%;
}

.scan {
    font-size: 60px;
}
.form-floating {
    .textareabox {
        height: 100px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
}
.ngb-dp-today {
    .btn-light {
        background-color: tint-color($primary, 70%);
    }
}
.ngb-dp-arrow-btn {
    padding: 0 7px !important;
    box-shadow: none !important;
}
.bg-gray {
    background-color: $gray-600;
}
.input-group {
    .form-floating + div {
        background: $input-bg;
    }
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
}

.step-container {
    flex-grow: 1;
    position: relative;
    opacity: 0.7;
    .icon-step {
        background-color: var(--bs-gray) !important;
    }
    &:before {
        background-color: var(--bs-gray) !important;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 30%;
        content: "";
        z-index: 0;
        transform: translateY(-30%);
        display: flex;
        flex-grow: 1;
    }
    &.active {
        opacity: 1;
        .icon-step {
            background: rgb(var(--bs-primary-rgb)) !important;
        }
        &:before {
            background-color: rgb(var(--bs-primary-rgb)) !important;
        }
    }
    &.reject {
        opacity: 1;
        .icon-step {
            background: rgb(var(--bs-danger-rgb)) !important;
        }
        &:before {
            background-color: rgb(var(--bs-danger-rgb)) !important;
        }
    }
}
.icon-step {
    margin-bottom: 0.3rem;
    .material-icons {
        position: absolute;
        z-index: 1;
    }
}

.badge {
    font-weight: 400;
}
