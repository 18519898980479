$input-btn-focus-width: 0.1rem !default;
$input-btn-focus-color: rgba(0, 71, 115, 0.25) !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-placeholder-color: #adadb4 !default;
$input-focus-width: $input-btn-focus-width !default;
$input-font-weight: 500 !default;
$input-bg: #f5f8fa !default;
$focus-ring-width: $input-btn-focus-width !default;
$input-group-addon-bg: $input-bg !default;
$font-family-sans-serif: Roboto, "Helvetica Neue", sans-serif !default;
$font-family-monospace: "Roboto Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-size-base: 0.9rem !default;
$font-weight-normal: 500 !default;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E") !default;
$modal-transition: cubic-bezier(0.4, 0, 0.2, 1) !default;
$modal-fade-transform: translate(0, -1050px) !default;
$modal-show-transform: none !default;
$transition-base: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !default;
$transition-fade: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) !default;
$transition-collapse: height 0.35s cubic-bezier(0.4, 0, 0.2, 1) !default;
$primary: #047979 !default;
$secondary: #327745 !default;
$success: #4d9605 !default;
$info: #0076b1 !default;
$warning: #ad6500 !default;
$danger: #b90028 !default;
$dark: #181c32 !default;
$light: #fff !default;
$card-border-width: 0 !default;
$card-box-shadow: 0px 0px 20px rgba(94, 98, 120, 0.04) !default;
$body-bg: #dae3ec !default;
$card-bg: $light !default;
$table-bg: $light !default;
$dropdown-bg: $light !default;
$modal-content-bg: $light !default;
$swal2-show-animation: $modal-transition !default;
$swal2-hide-animation: $modal-fade-transform !default;
